/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

function MyCompanyCard(props) {
  return (
    <div className="border border-[#EBEBEB] rounded-[8px] w-full md:w-1/2 lg:w-1/3">
      <header className="flex items-center border-b border-[#EBEBEB] py-5 px-6">
        <h1 className="text-semibold-18">{props.title}</h1>
      </header>
      <footer className="flex flex-col py-4 px-6 pb-6">
        <p className="text-reg-16 mt-4 mb-5">
          Title: {props.title}
          <br />
          {/* bundle : {props.bundle}s */}
        </p>

        <div className="flex items-center space-x-4">
          <button
            className="href-standard"
            href="#"
            onClick={() => props.onEditClick(props.id)}
          >
            Edit
          </button>
          {/* <button className="href-standard href-standard-alt" href="#">
            Delete
          </button> */}
        </div>
      </footer>
    </div>
  )
}

export default MyCompanyCard
