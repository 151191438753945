/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import { getMyListing } from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import Modal from "../../components/Modal/Modal"
import { BoxCardGroup, BoxCard } from "../../components/Cards/Dashboard/BoxCard"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import NotificationContext from "../../context/Notification/NotificationContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import Skeleton from "../../components/Skeleton"
import { Table, Tr, Td } from "../../components/Table/TableElements"
import { Tab } from "@headlessui/react"
import { classNames } from "../../utils"
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  ChatAlt2Icon,
  MenuAlt2Icon,
  PhotographIcon,
  PuzzleIcon,
  UsersIcon,
  XIcon,
  UserGroupIcon,
  ShoppingBagIcon,
  OfficeBuildingIcon,
  LogoutIcon,
} from "@heroicons/react/outline"
import Coupon from "../../assets/icons/coupon-alt.svg"
import Cart from "../../assets/icons/cart.svg"
import Shop from "../../assets/icons/shop.svg"
import MyCompanies from "../../templates/Dashboard/MyCompanies"
import MyBrands from "../../templates/Dashboard/MyBrands"
import MyRetailers from "../../templates/Dashboard/MyRetailers"
import MyStores from "../../templates/Dashboard/MyStores"
import AccessDenied from "../../components/AccessDenied"
import PageSubtitle from "../../components/PageSubtitle"
const TabTitle = props => {
  const { name, icon, stroke } = props
  return (
    <div
      className={"group flex items-center"}
      css={css`
        &&& {
          &:hover {
            path {
              fill: ${stroke ? `none` : `#717171`};
            }
          }
        }
      `}
    >
      <props.icon
        className={`mr-3 flex-shrink-0 ${
          !stroke ? `h-4 w-4` : `h-[1.1rem] w-[1.1rem]`
        }`}
        css={css`
          path {
            fill: ${!stroke ? `#222` : stroke ? `none` : `#222`};
            stroke-width: ${stroke ? `1.5px` : `/**/`};
          }
        `}
        aria-hidden="true"
      />
      {name}
    </div>
  )
}
const Tabs = props => {
  const { location } = props
  const { state } = useContext(StateContext)
  const { authentication } = useContext(AuthenticationContext)
  const { currentUserData } = authentication
  const { retailers, brands } = currentUserData
  const { toggleNotification } = useContext(NotificationContext)

  const brandTabs = [
    {
      name: <TabTitle name={"My Brands"} icon={UserGroupIcon} stroke={true} />,
      content: <MyBrands location={location} />,
    },
  ]

  const retailerTabs = [
    {
      name: <TabTitle name={"My Stores"} icon={Shop} />,
      content: <MyStores location={location} />,
    },
    {
      name: (
        <TabTitle name={"My Retailers"} icon={ShoppingBagIcon} stroke={true} />
      ),
      content: <MyRetailers location={location} />,
    },
  ]

  let tabs = []

  if (brands.length) tabs = [...tabs, ...brandTabs]
  if (retailers.length) tabs = [...tabs, ...retailerTabs]
  // tabs = [
  //   ...tabs,
  //   {
  //     name: (
  //       <TabTitle
  //         name={"My Companies"}
  //         icon={OfficeBuildingIcon}
  //         stroke={true}
  //       />
  //     ),
  //     content: <MyCompanies location={location} />,
  //   },
  // ]
  // let editId = location.state?.editId || location.state?.brand_id

  return (
    <div>
      <Tab.Group defaultIndex={0}>
        <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
          {tabs.map((item, k) => (
            <Tab
              key={k}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "border-[#222] text-[#222]"
                    : "border-transparent text-[#222] hover:text-gray-500",
                  "whitespace-nowrap pb-[28px] px-1 border-b-2 text-med-16"
                )
              }
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="pt-[30px]">
          {tabs.map((item, k) => {
            return <Tab.Panel key={k}>{item.content}</Tab.Panel>
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

function MyOrganization({ location }) {
  const { userType, authentication } = useContext(AuthenticationContext)
  const { currentUserData } = authentication
  const { retailers, brands } = currentUserData

  if (!userType.brandManager && !userType.retailerManager)
    return <AccessDenied />
  return (
    <LayoutDashborad
      heading={brands.length ? "My Brands" : "My Organization"}
      subheading={"Manage your organization and associated profiles here."}
      headingMargin={!userType.brandManager && "mt-20"}
    >
      <Tabs location={location} />
    </LayoutDashborad>
  )
}

export default MyOrganization
