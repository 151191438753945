import React from 'react';
import Button from "../Elements/Button";

function DeleteConfirm({ onDelete, onCancel }) {
  return (
    <div>
      <p className="mb-6">
        Are you sure you want to delete this? This action <b>cannot</b> be undone.
      </p>
      <div className="flex justify-between">
        <Button
          red
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          outline
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteConfirm;
