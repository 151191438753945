/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import {
  BoxCardGroup,
  BoxCardCreate,
  BoxCard,
} from "../../components/Cards/Dashboard/BoxCard"
import LayoutDashborad from "../../components/Layout/Dashboard"
import IconAddCircle from "../../components/Elements/IconAddCircle"
import { getMyListing, getMySingleForm } from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import Modal from "../../components/Modal/Modal"
import CircleAvatar from "../../components/Elements/CircleAvatar"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import NotificationContext from "../../context/Notification/NotificationContext"
import ButtonLite from "../../components/Elements/ButtonLite"
import Skeleton from "../../components/Skeleton"
import DeleteConfirm from "../../components/Forms/DeleteConfirm"
import BrandSingle from "../BrandSingle"
function MyBrands({ location }) {
  const { state } = useContext(StateContext)
  const [list, setList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [deleteCurrent, setDeleteCurrent] = useState(null)
  const [formData, setFormData] = useState(null)
  const [openCreate, setOpenCreate] = useState(false)
  const { toggleNotification } = useContext(NotificationContext)

  const onEditClick = v => {
    setEditCurrent(v)
    setOpen(true)
  }
  const onDeleteClick = v => {
    setDeleteCurrent(v)
    setOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteCurrent(null)
    setOpen(false)
  }
  const onSave = async (data, v) => {
    if (v === "added") {
      setOpenCreate(false)
      const datas = await getMyListing(state.token, "brand", "default", "all")

      setList(datas)
      toggleNotification({ content: "Brand Added Sucessfully" })
    } else if (v === "edited") {
      const datas = await getMyListing(state.token, "brand", "default", "all")

      setList(datas)
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: " Brand Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed" })
    }
  }
  useEffect(async () => {
    const data = await getMyListing(state.token, "brand", "default", "all")

    setList(data)
    let formData = await getMySingleForm(state.token, "brand")

    setFormData(formData)
  }, [])
  useEffect(async () => {
    let editId = location.state?.editId || location.state?.brand_id

    if (editId && list?.length) {
      onEditClick(editId)
    }
  }, [list])

  const [modalView, setModalView] = useState(null)

  const viewAsRetailer = brandId => {
    setModalView(brandId)
  }

  return (
    <>
      <div className="container lg:mb-12 mt-0 mx-auto">
        <BoxCardGroup>
          {/*<BoxCardCreate
            title={"Create New Brand"}
            callback={() => setOpenCreate(true)}
          />*/}
          {list === null ? (
            <Skeleton boxCard />
          ) : list.length > 0 ? (
            list.map((v, k) => {
              return (
                <BoxCard
                  title={
                    <>
                      <CircleAvatar
                        sm
                        border
                        image={
                          v.field_brand_logo?.length > 0
                            ? v.field_brand_logo[0].thumb
                            : null
                        }
                        text={
                          <div className="flex flex-col leading-[1.2]">
                            {v.title}

                            {v.field_active === "1" ? (
                              <span
                                style={{
                                  color: "#009D4F",
                                  fontWeight: 400,
                                  fontSize: "11px",
                                }}
                              >
                                Listed
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "#e43d30",
                                  fontWeight: 400,
                                  fontSize: "11px",
                                }}
                              >
                                Unlisted
                              </span>
                            )}
                          </div>
                        }
                      />
                    </>
                  }
                  tr={
                    <ButtonLite
                      color={"grey"}
                      callback={() => viewAsRetailer(v.id)}
                    >
                      View as Retailer
                    </ButtonLite>
                  }
                  callbackDelete={onDeleteClick}
                  callbackEdit={() => onEditClick(v.id)}
                  deleteDisable
                  id={v.id}
                >
                  Regions:{" "}
                  {v.field_brand_regions.length > 0
                    ? v.field_brand_regions.map(
                        (c, k) =>
                          `${c.title}${
                            v.field_brand_regions?.length !== k + 1 ? ", " : ""
                          }`
                      )
                    : "All"}
                  <br />
                </BoxCard>
              )
            })
          ) : (
            "No items to display"
          )}
        </BoxCardGroup>
      </div>

      <Modal
        isOpen={modalView === null ? false : true}
        closeModal={() => {
          setModalView(null)
        }}
        full
        // screenHeight
        p0
      >
        {modalView !== null && <BrandSingle id={modalView} readOnly />}
      </Modal>
      <Modal
        title={"Create New Brand"}
        isOpen={openCreate}
        closeModal={() => {
          setOpenCreate(false)
        }}
      >
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
            name="brand"
            bundle="default"
          />
        )}
      </Modal>

      {open && editCurrent && (
        <Modal
          isOpen={open}
          closeModal={() => {
            setEditCurrent(null)
            setOpen(false)
          }}
          title={"Edit Brand"}
        >
          <EditFormTemplate
            edit
            id={editCurrent}
            token={state.token}
            bundle="default"
            name={"brand"}
            list={list}
            onSave={onSave}
          />
        </Modal>
      )}
      {open && deleteCurrent && (
        <Modal
          isOpen={open}
          closeModal={closeDeleteModal}
          title={"Delete brand"}
        >
          <DeleteConfirm
            onDelete={() => console.log("delete", deleteCurrent)}
            onCancel={closeDeleteModal}
          />
        </Modal>
      )}
      {openAfterSave && (
        <Modal
          isOpen={openAfterSave}
          closeModal={() => {
            setOpenAfterSave(false)
          }}
        >
          <h2>{contentAfterSave}</h2>
        </Modal>
      )}
    </>
  )
}

export default MyBrands
