/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"

import StoreCard from "../../components/Cards/Dashboard/StoreCard"

import LayoutDashborad from "../../components/Layout/Dashboard"
import IconAddCircle from "../../components/Elements/IconAddCircle"

import {
  addMySingleNewForm,
  deleteMySingleForm,
  getStoreForm,
  getMyListing,
  updateMySingleEditForm,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import CreateFormStore from "../../components/Forms/CreateFormStore"
import Modal from "../../components/Modal/Modal"
import {
  BoxCardGroup,
  BoxCardCreate,
  BoxCard,
} from "../../components/Cards/Dashboard/BoxCard"
import { faL } from "@fortawesome/free-solid-svg-icons"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import NotificationContext from "../../context/Notification/NotificationContext"
import Skeleton from "../../components/Skeleton"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import DeleteConfirm from "../../components/Forms/DeleteConfirm"
function MyStores() {
  const { state } = useContext(StateContext)
  const { authentication } = useContext(AuthenticationContext)
  const { toggleNotification, notificationIsVisible } =
    useContext(NotificationContext)
  const userId = authentication.currentUserData.i
  const [list, setList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [deleteCurrentId, setDeleteCurrentId] = useState(null)
  const [formData, setFormData] = useState(null)

  const onEditClick = v => {
    setEditCurrent(v)
    setOpen(true)
  }
  const onDeleteClick = id => {
    setDeleteCurrentId(id)
    setOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteCurrentId(null)
    setOpen(false)
  }
  const handleDelete = async () => {
    const res = await deleteMySingleForm(
      state.token,
      "store",
      "retail_store",
      deleteCurrentId
    )
    if (res.ok) {
      toggleNotification({
        content: deleteCurrentId + " Deleted Sucessfully",
      })
      const data = await getMyListing(
        state.token,
        "store",
        "retail_store",
        "all"
      )

      setList(data)
    } else {
      toggleNotification({
        content: deleteCurrentId + " Failed to delete ",
        error: true,
      })
    }
  }
  const onSave = async (data, v) => {
    // alert(v)
    // console.log(data)
    if (v === "added") {
      let res = await addMySingleNewForm(
        state.token,
        "store",
        "retail_store",
        data
      )
      if (res.status === 200)
        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">Store Added</p>
          ),
        })
      else
        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">
              fail to add Store
            </p>
          ),
        })
      // let newList = [...list, data]
      // setList(await getMyListing(state.token, "store", "retail_store"))
      const newD = await getMyListing(
        state.token,
        "store",
        "retail_store",
        "all"
      )
      // console.log(data)
      setList([...newD])
      setOpenCreate(false)
    } else if (v === "edited") {
      // console.log(data)

      // let res2 = await updateMySingleEditForm(
      //   state.token,
      //   "store",
      //   "retail_store",
      //   editCurrent,
      //   data
      // )
      // if (res2.status === 200)
      toggleNotification({
        content: (
          <p className="text-sm font-medium text-gray-900">Store edited</p>
        ),
      })

      const newD = await getMyListing(
        state.token,
        "store",
        "retail_store",
        "all"
      )
      // console.log(data)
      setList([...newD])
      setOpen(false)
      setEditCurrent(null)

      // setOpenAfterSave(true)

      // setContentAfterSave("Edited Added Sucessfully")
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({
        content: (
          <p className="text-sm font-medium text-red-600">{data}Failed </p>
        ),
      })
    }
  }

  useEffect(async () => {
    // console.log(state)
    const data = await getMyListing(state.token, "store", "retail_store", "all")
    // console.log(data)
    setList([...data])
    let formData = await getStoreForm(state.token)
    setFormData(formData)
  }, [])
  const [openCreate, setOpenCreate] = useState(false)
  // console.log(list)
  return (
    <>
      <div className="container mb-12 mt-0 mx-auto">
        <BoxCardGroup>
          <BoxCardCreate
            title={"Create New Store"}
            callback={() => setOpenCreate(true)}
          />

          {list === null || !Array.isArray(list) ? (
            <Skeleton boxCard />
          ) : list.length > 0 ? (
            list.map((v, k) => (
              <BoxCard
                key={k}
                title={v.title}
                callbackEdit={() => onEditClick(v.id)}
                callbackDelete={() => onDeleteClick(v.id)}
              >
                <StoreCard {...v} />
              </BoxCard>
            ))
          ) : (
            "No items to display"
          )}
        </BoxCardGroup>
        {/*<div className="grid grid-cols-3 space-x-4 space-y-4">
          <a
            href="#create"
            className="  --border-dashed --border --border-[#E43D30] lg:px-4 rounded-[8px] flex items-center justify-center flex-col"
            css={css`
              background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E43D30' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
            `}
          >
            <IconAddCircle className="w-[70px] h-[70px] mb-5" />
            <span className="text-semibold-20">Create New Store</span>
          </button>
          {list &&
            list.map((v, k) => (
              <BoxCard
                // {...v}
                onDeleteClick={v => console.log("edit", v)}
                title={v.name}
                onEditClick={onEditClick}
                id={v.id}
              >
                {v.location}
                <br />
                Phone Number: {v.tel_1}
                <br />
                Second Phone Number: {v.tel_2}
              </BoxCard>
            ))}
        </div>*/}
      </div>
      {/* <SectionCard title={"Create New Store"} id="create">
      </SectionCard> */}
      <Modal
        title={"Create New Store"}
        isOpen={openCreate}
        closeModal={() => {
          if (!notificationIsVisible) setOpenCreate(false)
        }}
      >
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
            name="store"
            bundle="retail_store"
          />
        )}
      </Modal>
      {open && editCurrent && (
        <Modal
          isOpen={open}
          closeModal={() => {
            if (!notificationIsVisible) {
              setOpen(false)
              setEditCurrent(null)
            }
          }}
          title={"Edit Store"}
        >
          <EditFormTemplate
            edit
            id={editCurrent}
            token={state.token}
            bundle="retail_store"
            name={"store"}
            list={list}
            onSave={onSave}
          />
        </Modal>
      )}
      {open && deleteCurrentId && (
        <Modal
          isOpen={open}
          closeModal={closeDeleteModal}
          title={"Delete store"}
        >
          <DeleteConfirm
            onDelete={() => {
              handleDelete().then(closeDeleteModal)
            }}
            onCancel={closeDeleteModal}
          />
        </Modal>
      )}
      <Modal
        isOpen={openAfterSave}
        closeModal={() => {
          setOpenAfterSave(false)
        }}
      >
        <h2>{contentAfterSave}</h2>
      </Modal>
      {/* <SectionCard title="Create New Store" id="create">
        <form>
          <FloatingInput label="Store Nickname" />
          <FloatingInput label="Address 1" />
          <FloatingInput label="Address 2" />

          <div className="grid xl:grid-cols-2 xl:gap-6">
            <FloatingInput label="First name" />
            <FloatingInput label="Last name" />
          </div>

          <div className="grid xl:grid-cols-2 xl:gap-6">
            <FloatingInput label="Phone number" />
            <FloatingInput label="Company" />
          </div>
          <Button type="submit" red onClick={() => console.log("click")}>
            Add Store
          </Button>
        </form>
      </SectionCard> */}
    </>
  )
}

export default MyStores
