/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import {
  BoxCardGroup,
  BoxCardCreate,
  BoxCard,
} from "../../components/Cards/Dashboard/BoxCard"
import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import BreadCrumb from "../../components/Elements/BreadCrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import IconAddCircle from "../../components/Elements/IconAddCircle"
import FloatingInput from "../../components/Elements/Form/FloatingInput"
import Button from "../../components/Elements/Button"

import {
  getMyListing,
  getMySingleForm,
  // getUserStoresListing,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import CreateFormStore from "../../components/Forms/CreateFormStore"
import Modal from "../../components/Modal/Modal"
import { faL } from "@fortawesome/free-solid-svg-icons"
import MyCompanyCard from "../../components/Cards/Dashboard/MyCompanyCard"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import NotificationContext from "../../context/Notification/NotificationContext"
import Skeleton from "../../components/Skeleton"
import DeleteConfirm from "../../components/Forms/DeleteConfirm";
function MyCompanies({ location }) {
  const { state } = useContext(StateContext)
  const [list, setList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [deleteCurrent, setDeleteCurrent] = useState(null)
  const [formData, setFormData] = useState(null)

  const onEditClick = v => {
    setEditCurrent(v)
    setOpen(true)
  }
  const onDeleteClick = v => {
    setDeleteCurrent(v)
    setOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteCurrent(null)
    setOpen(false)
  }
  const { toggleNotification } = useContext(NotificationContext)

  const onSave = async (data, v) => {
    if (v === "added") {
      const datas = await getMyListing(state.token, "retailer", "default", "all")
      console.log(datas)
      setList(datas)
      toggleNotification({ content: "Retailer Added Sucessfully" })
    } else if (v === "edited") {
      setList([...data])
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: " Retailer Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed" })
    }
  }
  useEffect(async () => {
    console.log(state)
    const data = await getMyListing(state.token, "retailer", "default", "all")
    console.log(data)
    setList(data)
    let formData = await getMySingleForm(state.token, "retailer")
    console.log(formData)
    setFormData(formData)
    let editId = location.state?.editId
    if (editId) {
      setEditCurrent(editId)
      setOpen(true)
    }
  }, [])
  useEffect(() => {
    let editId = location.state?.editId
    if (editId) {
      setEditCurrent(editId)
      setOpen(true)
    }
  }, [location])
  const [openCreate, setOpenCreate] = useState(false)

  return (
    <>
      <div className="container mb-12 mt-0 mx-auto">
        <BoxCardGroup>
          <BoxCardCreate
            title={"Create New Retailer"}
            callback={() => setOpenCreate(true)}
          />

          {list === null ? (
            <Skeleton boxCard />
          ) : list.length > 0 ? (
            list.map((v, k) => (
              <BoxCard
                // {...v}
                callbackDelete={onDeleteClick}
                title={v.title}
                callbackEdit={() => onEditClick(v.id)}
                id={v.id}
                deleteDisable
              >
                Companies:{" "}
                {v.field_retailer_company?.length &&
                  v.field_retailer_company.map((v, k) =>
                    k === 0 ? v.title : ", " + v.title
                  )}
                <br />
                Stores:{" "}
                {v.field_retailer_stores.map((v, k) =>
                  k === 0 ? v.title : ", " + v.title
                )}
              </BoxCard>
            ))
          ) : (
            "No items to display"
          )}
        </BoxCardGroup>
        <div className="flex space-x-4">
          {/*  <button
            onClick={() => setOpenCreate(true)}
            className="w-full md:w-1/2 lg:w-1/3  --border-dashed --border --border-[#E43D30] lg:px-4 rounded-[8px] flex items-center justify-center flex-col"
            css={css`
              background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E43D30' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
            `}
          >
            <IconAddCircle className="w-[70px] h-[70px] mb-5" />
            <span className="text-semibold-20">Create New Store</span>
          </button>*/}
        </div>
      </div>
      <Modal
        title={"Create New Retailer"}
        isOpen={openCreate}
        closeModal={() => {
          setOpenCreate(false)
        }}
      >
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
            name="retailer"
            bundle="default"
          />
        )}
      </Modal>
      {/* <SectionCard title={"Create New Retailer"} id="create">
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
            name="retailer"
            bundle="default"
          />
        )}
      </SectionCard> */}
      {open && editCurrent && (
        <Modal
          isOpen={open}
          closeModal={() => {
            setEditCurrent(null)
            setOpen(false)
          }}
          title={"Edit Retailer"}
        >
          <EditFormTemplate
            edit
            id={editCurrent}
            token={state.token}
            bundle="default"
            name={"retailer"}
            list={list}
            onSave={onSave}
          />
        </Modal>
      )}
      {open && deleteCurrent && (
        <Modal
          isOpen={open}
          closeModal={closeDeleteModal}
          title={"Delete retailer"}
        >
          <DeleteConfirm
            onDelete={() => console.log("delete", deleteCurrent)}
            onCancel={closeDeleteModal}
          />
        </Modal>
      )}
      {openAfterSave && (
        <Modal
          isOpen={openAfterSave}
          closeModal={() => {
            setOpenAfterSave(false)
          }}
        >
          <h2>{contentAfterSave}</h2>
        </Modal>
      )}
      {/* <SectionCard title="Create New Store" id="create">
        <form>
          <FloatingInput label="Store Nickname" />
          <FloatingInput label="Address 1" />
          <FloatingInput label="Address 2" />

          <div className="grid xl:grid-cols-2 xl:gap-6">
            <FloatingInput label="First name" />
            <FloatingInput label="Last name" />
          </div>

          <div className="grid xl:grid-cols-2 xl:gap-6">
            <FloatingInput label="Phone number" />
            <FloatingInput label="retailer" />
          </div>
          <Button type="submit" red onClick={() => console.log("click")}>
            Add Store
          </Button>
        </form>
      </SectionCard> */}
    </>
  )
}

export default MyCompanies
