/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"

import LayoutDashborad from "../../components/Layout/Dashboard"

import { getMyListing } from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"

import Modal from "../../components/Modal/Modal"

import { BoxCardGroup, BoxCard } from "../../components/Cards/Dashboard/BoxCard"

import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import NotificationContext from "../../context/Notification/NotificationContext"
import Skeleton from "../../components/Skeleton"
import DeleteConfirm from "../../components/Forms/DeleteConfirm";
function MyCompanies({ location }) {
  // console.log(location)
  const { state } = useContext(StateContext)
  const [list, setList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [deleteCurrent, setDeleteCurrent] = useState(null)
  const [formData, setFormData] = useState(null)
  const { toggleNotification } = useContext(NotificationContext)

  const onEditClick = v => {
    setEditCurrent(v)
    setOpen(true)
  }
  const onDeleteClick = v => {
    setDeleteCurrent(v)
    setOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteCurrent(null)
    setOpen(false)
  }
  const onSave = (data, v) => {
    if (v === "added") {
      let newList = [...list, data]
      setList([...newList])
    } else if (v === "edited") {
      setList([...data])
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: " Company Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed" })
    }
  }
  useEffect(async () => {
    const data = await getMyListing(state.token, "company", "default", "all")
    setList(data)
    let editId = location.state?.editId
    if (editId) {
      setEditCurrent(editId)
      setOpen(true)
    }
  }, [])
  return (
    <>
      <div className="container mb-12 mt-0 mx-auto">
        <BoxCardGroup>
          {list === null ? (
            <Skeleton boxCard />
          ) : list.length > 0 ? (
            list.map((v, k) => (
              <BoxCard
                // {...v}

                title={v.title}
                callbackEdit={() => onEditClick(v.id)}
                callbackDelete={onDeleteClick}
                deleteDisable
                id={v.id}
              >
                Title: {v.title}
                <br />
              </BoxCard>
            ))
          ) : (
            "No items to display"
          )}
        </BoxCardGroup>
        <div className="flex space-x-4">
          {/* <a
            href="#create"
            className="w-full md:w-1/2 lg:w-1/3  --border-dashed --border --border-[#E43D30] lg:px-4 rounded-[8px] flex items-center justify-center flex-col"
            css={css`
              background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E43D30' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
            `}
          >
            <IconAddCircle className="w-[70px] h-[70px] mb-5" />
            <span className="text-semibold-20">Create New Company</span>
          </a> */}
        </div>
      </div>
      {/* <SectionCard title={"Create New Company"} id="create">
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
          />
        )}
      </SectionCard> */}
      {open && editCurrent && (
        <Modal
          isOpen={open}
          closeModal={() => {
            setEditCurrent(null)
            setOpen(false)
          }}
          title={"Edit Company"}
        >
          <EditFormTemplate
            edit
            id={editCurrent}
            token={state.token}
            bundle="default"
            name={"company"}
            list={list}
            onSave={onSave}
          />
        </Modal>
      )}
      {open && deleteCurrent && (
        <Modal
          isOpen={open}
          closeModal={closeDeleteModal}
          title={"Delete company"}
        >
          <DeleteConfirm
            onDelete={() => console.log("delete", deleteCurrent)}
            onCancel={closeDeleteModal}
          />
        </Modal>
      )}
      {openAfterSave && (
        <Modal
          isOpen={openAfterSave}
          closeModal={() => {
            setOpenAfterSave(false)
          }}
        >
          <h2>{contentAfterSave}</h2>
        </Modal>
      )}
      {/* <SectionCard title="Create New Store" id="create">
        <form>
          <FloatingInput label="Store Nickname" />
          <FloatingInput label="Address 1" />
          <FloatingInput label="Address 2" />

          <div className="grid xl:grid-cols-2 xl:gap-6">
            <FloatingInput label="First name" />
            <FloatingInput label="Last name" />
          </div>

          <div className="grid xl:grid-cols-2 xl:gap-6">
            <FloatingInput label="Phone number" />
            <FloatingInput label="Company" />
          </div>
          <Button type="submit" red onClick={() => console.log("click")}>
            Add Store
          </Button>
        </form>
      </SectionCard> */}
    </>
  )
}

export default MyCompanies
