/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

function StoreCard(props) {
  // console.log(props)
  return (
    <>
      Companies:
      {props.field_store_company?.length &&
        props.field_store_company.map((v, k) =>
          k === 0 ? v.title : ", " + v.title
        )}
      <br />
      {props?.field_store_retailer &&
        Array.isArray(props?.field_store_retailer) &&
        props?.field_store_retailer?.length > 0 && (
          <>
            Stores:{" "}
            {props?.field_store_retailer?.map((v, k) =>
              k === 0 ? v.title : ", " + v.title
            )}
            <br />
          </>
        )}
      Location:{" "}
      {Array.isArray(props.field_store_location) && props.field_store_location?.length > 0 &&
        props.field_store_location.map((v, k) => (
          <>
            {v.title}
            <br />
          </>
        ))}
      <br />
      {props.location?.split(" ").join("") !== "" && (
        <>
          {props.location} <br />
        </>
      )}
      {props.tel_1 && (
        <>
          Primary Telephone: {props.tel_1}
          <br />
        </>
      )}
      {props.tel_2 && <>Secondary Telephone: {props.tel_2}</>}
      {/* {props.field_store_website && <>Website: {props.field_store_website}</>} */}
    </>
  )
}

export default StoreCard
